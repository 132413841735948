import React from 'react';
import { FaTelegram, FaWhatsapp } from 'react-icons/fa';
import { CiMail } from 'react-icons/ci';
import { Tooltip } from 'antd';
import s from './Social.module.scss';

const sizeIcon = 40;
const colorIcon = 'rgb(185, 180, 160)';
const phone = '+79265905390';

export const Social = () => {
    return (
        <ul className={s.social}>
            <li className={s.social__item}>
                <Tooltip title={'WhatsApp'}>
                    <a title="Whatsapp" href="whatsapp://send?phone=79265905390" target='_blank'>
                        <FaWhatsapp color={colorIcon} size={sizeIcon} />
                    </a>
                </Tooltip>
            </li>
            <li className={s.social__item}>
                <Tooltip title={'Telegram'}>
                    <a title="Telegram" href={`https://t.me/${phone}`} target='_blank'>
                        <FaTelegram color={colorIcon} size={sizeIcon} />
                    </a>
                </Tooltip>
            </li>
            <li className={s.social__item}>
                <Tooltip title={'Mail'}>
                    <a href="mailto:Genya227@mail.ru">
                        <CiMail color={colorIcon} size={sizeIcon} />
                    </a>
                </Tooltip>
            </li>
        </ul>
    );
};